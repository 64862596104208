import { Text } from '@/components/text';
import { styled } from '@/stitches.config';
import type { ReactNode } from 'react';
import { Fragment } from 'react';

interface BlockquoteProps {
  children: ReactNode;
  quotee?: string;
  source?: string;
  isStandalone?: boolean;
}

export function Blockquote({
  children,
  source,
  quotee,
  isStandalone,
}: BlockquoteProps) {
  const Wrapper = isStandalone ? Fragment : 'figure';

  return (
    <Wrapper>
      <Quote>
        <Text
          as="p"
          size={{
            '@initial': 4,
            '@bp3': 5,
          }}
          css={{ mb: 0 }}
        >
          {children}
        </Text>
      </Quote>
      {quotee && (
        <Caption>
          <Text
            as="p"
            size={2}
            css={{
              fontWeight: '$heavy',
              mb: '0',
            }}
          >
            {quotee}
          </Text>
          <Text as="p" size={2} css={{ mb: 0 }}>
            {source}
          </Text>
        </Caption>
      )}
    </Wrapper>
  );
}

interface StandaloneBlockquoteProps extends BlockquoteProps {
  alignment?: 'left' | 'middle' | 'right';
}

export function StandaloneBlockquote({
  children,
  source,
  quotee,
  alignment = 'right',
}: StandaloneBlockquoteProps) {
  return (
    <Wrapper alignment={alignment}>
      <Blockquote quotee={quotee} source={source} isStandalone>
        {children}
      </Blockquote>
    </Wrapper>
  );
}

const Wrapper = styled('figure', {
  width: '100%',

  variants: {
    alignment: {
      left: {
        '@bp3': {
          gridColumn: '3 / span 8',
        },
      },
      middle: {
        '@bp3': {
          gridColumn: '6 / span 8',
        },
      },
      right: {
        '@bp3': {
          gridColumn: '9 / span 8',
        },
      },
    },
  },
});

const Quote = styled('blockquote', {
  marginLeft: '$7',
  marginBottom: '2em',

  '&:before': {
    content: '“',
    fontSize: '$7',
    marginTop: '-$1',
    marginLeft: '-$7',
    position: 'absolute',
  },

  '@bp3': {
    marginLeft: 0,
    '&:before': {
      marginLeft: '-$7',
    },
  },
});

const Caption = styled('figcaption', {
  marginLeft: '$7',
  '@bp3': {
    marginLeft: 0,
  },
});
