import { urlForImage } from '@/lib/sanity/sanity';
import { styled } from '@/stitches.config';
import { SanityResolvedImage } from '@/types';
import { closeModal, openModal } from '@/utils/modal';
import debounce from 'debounce';
import { motion, useMotionValue, useReducedMotion } from 'framer-motion';
import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';

type ExpandingImageProps = {
  image: SanityResolvedImage<{ alt?: string }>;
};

export function ExpandingImage({ image }: ExpandingImageProps) {
  const [expanded, setExpanded] = useState(false);
  const [expandable, setExpandable] = useState(true);
  const zIndex = useMotionValue(0);
  const reduceMotion = useReducedMotion();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const wrapperElement = wrapperRef.current;

  useEffect(() => {
    const debouncedResize = debounce(onResize, 100, true);
    window.addEventListener('resize', debouncedResize);
    onResize();

    return () => {
      window.removeEventListener('resize', debouncedResize);
    };
  });

  const { asset, alt } = image;
  const { metadata } = asset;
  const lqip = metadata?.lqip;
  const imageUrl = urlForImage(asset).url();

  if (!imageUrl) {
    return null;
  }

  function onResize() {
    const imageWidth = wrapperElement?.clientWidth || 0;
    const canExpand = imageWidth < 0.8 * window.innerWidth;
    setExpandable(canExpand);
    if (expanded && !canExpand) {
      setExpanded(false);
    }
  }

  function expand() {
    zIndex.set(6);
    setExpanded(true);
    wrapperElement && openModal(wrapperElement, close);
  }

  function close() {
    setExpanded(false);
    closeModal();
  }

  function afterAnimation() {
    if (!expanded) {
      zIndex.set(0);
    }
  }

  return (
    <div ref={wrapperRef}>
      <Overlay onClick={close} visible={expanded} />
      <StyledExpandingImage
        style={{ zIndex }}
        layout={!reduceMotion}
        onLayoutAnimationComplete={afterAnimation}
        expanded={expanded}
      >
        <Image
          alt={alt}
          draggable="false"
          src={imageUrl}
          layout="fill"
          objectFit="cover"
          placeholder="blur"
          blurDataURL={lqip}
        />

        {expandable && (
          <ExpandButton
            onClick={() => (expanded ? close() : expand())}
            expanded={expanded}
            layout={!reduceMotion}
            aria-label={
              expanded ? `Close expanded image: ${alt}` : `Expand image: ${alt}`
            }
          />
        )}
      </StyledExpandingImage>
    </div>
  );
}

const Overlay = styled('div', {
  position: 'fixed',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  background: 'rgba(0, 0, 0, 0.8)',
  transition: 'opacity 0.2s linear',
  zIndex: '$overlay',

  variants: {
    visible: {
      true: {
        opacity: 1,
        pointerEvents: 'all',
      },
      false: {
        opacity: 0,
        pointerEvents: 'none',
      },
    },
  },
});

const StyledExpandingImage = styled(motion.div, {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',

  variants: {
    expanded: {
      true: {
        position: 'fixed',
        width: 'calc(100% - $6)',
        height: 'auto',
        maxWidth: `${(16 / 9) * 100}vh`,
        maxHeight: `${(9 / 16) * 100}vw`,
        top: '$3',
        bottom: '$3',
        left: 0,
        right: 0,
        margin: 'auto',

        '&::before': {
          content: '',
          display: 'block',
          width: '100%',
          paddingBottom: '56.25%',
        },
      },
    },
  },
});

const ExpandButton = styled(motion.button, {
  position: 'absolute',
  left: '$4',
  bottom: '$4',
  width: '$8',
  height: '$8',
  background: '$background',
  boxShadow: '2px 2px 5px 1px rgba(0, 0, 0, 0.1)',

  '&::before, &::after': {
    content: '',
    position: 'absolute',
    display: 'block',
    width: '10px',
    height: '10px',
    borderColor: '$foreground',
    borderStyle: 'solid',
    '@motion': {
      transition: 'transform 0.5s 0.1s',
    },
  },

  '&::before': {
    borderWidth: '0 0 1px 1px',
    top: 'calc(50% - 2px)', // -5px to center, ±3px to space them correctly
    left: 'calc(50% - 8px)',
    transformOrigin: '3px 7px',
  },

  '&::after': {
    borderWidth: '1px 1px 0 0',
    top: 'calc(50% - 8px)', // -5px to center, ±3px to space them correctly
    left: 'calc(50% - 2px)',
    transformOrigin: '7px 3px',
  },

  '&:hover': {
    '&:before': {
      top: 'calc(50% - 1px)', // -5px to center, ±4px to space them correctly
      left: 'calc(50% - 9px)',
    },
    '&:after': {
      top: 'calc(50% - 9px)', // -5px to center, ±4px to space them correctly
      left: 'calc(50% - 1px)',
    },
  },

  variants: {
    expanded: {
      true: {
        '&::before': {
          transform: 'translate(-1px, 1px) scale(-1)',
        },
        '&::after': {
          transform: 'translate(1px, -1px) scale(-1)',
        },
        '&:hover': {
          '&:before': {
            top: 'calc(50% - 3px)', // -5px to center, ±2px to space them correctly
            left: 'calc(50% - 7px)',
          },
          '&:after': {
            top: 'calc(50% - 7px)', // -5px to center, ±2px to space them correctly
            left: 'calc(50% - 3px)',
          },
        },
      },
    },
  },
});
