import { ButtonLink } from '@/components/button-link';
import { ExternalLinkProps } from '@/lib/sanity/portable-text';

export function ExternalLink(props: ExternalLinkProps) {
  return (
    <ButtonLink
      type={props.value.type}
      isInline={props.value.isInline}
      link={props.value.link}
      internalLink={props.value.internalLink}
      suffix={props.value.suffix}
    >
      {props.value.text}
    </ButtonLink>
  );
}
