import { TLogo } from '@/lib/sanity/portable-text';

import type {} from '@/types/sanity';

type HrefInternalExternalProps = Pick<
  TLogo,
  'externalLink' | 'internalLink' | 'linkType'
>;

export function getHrefFromInternalOrExternal(item: HrefInternalExternalProps) {
  let href;
  if (item.linkType === 'internal' && item.internalLink?.type) {
    const prefix = item.internalLink.type;
    href = `/${prefix}/${item.internalLink.slug}`;
  }
  if (item.linkType === 'external') {
    href = item.externalLink?.link;
  }
  return href;
}
