import { CSS, styled } from '@/stitches.config';
import { Box } from '@/components/box';

import {
  ExternalLink,
  Milestones as TMilestones,
  SanityBlock,
} from '@/types/sanity';
import { PortableText } from '@portabletext/react';
import { isPresent } from 'ts-is-present';

import { urlForImage } from '@/lib/sanity/sanity';
import { SanityResolvedImage } from '@/types';
import { KeenSliderOptions } from 'keen-slider/react';
import { SliderComposeable } from '@/components/serializers/sliders/core/slider-composeable';

import { ButtonText } from '../button-text';
import { TreemapImage } from '../treemap-image';

const config = {
  loop: false,
  mode: 'free-snap',
  slides: {
    perView: 1.2,
  },

  breakpoints: {
    '(min-width: 540px)': {
      slides: {
        perView: 1.7,
      },
    },
    '(min-width: 640px)': {
      slides: {
        perView: 2.2,
      },
    },
    '(min-width: 840px)': {
      slides: {
        perView: 2.7,
      },
    },
    '(min-width: 1024px)': {
      slides: {
        perView: 3.2,
      },
    },
    '(min-width: 1180px)': {
      slides: {
        perView: 3.7,
      },
    },
    '(min-width: 1280px)': {
      slides: {
        perView: 4.2,
      },
    },
  },
} as KeenSliderOptions;

interface MilestonesProps {
  milestones: NonNullable<TMilestones['slides']>;
}

export function Milestones(props: MilestonesProps) {
  const { milestones } = props;

  // Sort milestone items in descending order
  const sortedMilestones = milestones
    .filter(isPresent)
    .sort((a, b) => new Date(b.year).getTime() - new Date(a.year).getTime());

  return (
    <SliderComposeable
      css={{
        paddingLeft: '$4',
        '@bp3': {
          paddingLeft: 'calc($6 + calc(100vw / 16))',
        },
        '@bp4': {
          paddingLeft: 'calc($8 + calc(100vw / 16))',
          paddingRight: 'calc($6 - $2)',
        },
      }}
      config={config}
      slides={sortedMilestones.map((item, index, arr) => {
        const prev = arr[index - 1];
        const next = arr[index + 1];
        const showYear = !prev || prev.year !== item.year;
        const hasNext = next?.year !== undefined && next.year !== item.year;

        return (
          <MilestoneItem
            key={item._key}
            year={showYear ? item.year : '\u00A0'}
            subheading={item.subtitle}
            content={item.content}
            // @ts-expect-error todo fix this later
            image={item.image}
            css={{
              paddingRight: index + 1 === sortedMilestones.length ? 0 : '$6',
              paddingBottom: '2.375rem',
            }}
            link={item.link}
            showDot={showYear}
            nextHasDot={hasNext}
          />
        );
      })}
    />
  );
}

type MilestoneItemProps = {
  image: SanityResolvedImage<{ alt?: string }>;
  year: string | number;
  subheading: string;
  content?: SanityBlock | SanityBlock[];
  css: CSS;
  link?: ExternalLink;
  showDot: boolean;
  nextHasDot: boolean;
};

function MilestoneItem({
  content,
  css,
  link,
  image,
  subheading,
  year,
  showDot,
  nextHasDot,
}: MilestoneItemProps) {
  const { metadata } = image.asset;
  const { palette } = metadata;

  const imageUrl = urlForImage(image?.asset).width(640).url();
  if (!imageUrl) {
    return null;
  }

  return (
    <ItemWrapper className="keen-slider__slide">
      <Box>
        <Year>{year && <Year>{year}</Year>}</Year>
        <HorizontalTimeline showDot={showDot} nextHasDot={nextHasDot} />
      </Box>

      <Box css={css}>
        <ImageContainer>
          <Box
            css={{
              position: 'relative',
              aspectRatio: '1 / 1',
              '@supports not (aspect-ratio: 1 / 1)': {
                paddingBottom: '100%',
              },
            }}
          >
            <TreemapImage
              alt={image.alt}
              draggable="false"
              src={imageUrl}
              layout="fill"
              objectFit="cover"
              palette={palette}
            />
          </Box>
        </ImageContainer>
        <Subheading>{subheading && subheading}</Subheading>

        {content && <PortableText value={content} />}

        {link?.text && (
          <ButtonText
            css={{
              position: 'absolute',
              bottom: '0',
            }}
            label={link.text}
            href={setHref(link)}
            isInternalLink={!!link.internalLink}
          />
        )}
      </Box>
    </ItemWrapper>
  );
}

const Year = styled('div', {
  mb: '$3',
  height: '50px',
  display: 'flex',
  alignItems: 'flex-end',
});

const HorizontalTimeline = styled('div', {
  boxSizing: 'content-box',
  height: '1px',
  backgroundColor: '$foreground',
  mb: '$7',
  position: 'relative',
  overflow: 'visible',
  backfaceVisibility: 'hidden', // anti aliasing on psuedo element

  variants: {
    showDot: {
      true: {
        // milestone dot
        '&::before': {
          content: '',
          display: 'block',
          width: '11px',
          height: '11px',
          background: '$foreground',
          borderRadius: '50%',
          position: 'absolute',
          border: '5px solid $background',
          transform: 'translate(-5px, -50%)', //re-align dot to the left

          '@bp2': {
            width: '13px',
            height: '13px',
          },
        },
      },
    },
    nextHasDot: {
      true: {
        width: 'calc(100% - 5px)',
      },
      false: {
        width: '100%',
      },
    },
  },
});

const ItemWrapper = styled('div', {
  // makes space for the read me button to position it absolutely
  position: 'relative',
  overflow: 'visible !important', // it's hidden by default from keen-slider

  '&:last-of-type': {
    // vertical ending of timeline ruler
    [`${HorizontalTimeline}:after`]: {
      content: '',
      display: 'block',
      width: '1px',
      height: '11px',
      backgroundColor: '$foreground',
      position: 'absolute',
      right: 0,
      transform: 'translateY(-50%)',

      '@bp2': {
        height: '13px',
      },
    },
    paddingRight: '$6',
  },
});

const ImageContainer = styled('div', {
  aspectRatio: 1 / 1,
});

const Subheading = styled('h3', {
  fontSize: '$2',
  fontWeight: '$heavy',
  lineHeight: '$4',
  mt: '$5',
  mb: '$3',

  '@bp3': {
    fontSize: '$3',
    lineHeight: '$5',
  },
});

const setHref = (link: ExternalLink) => {
  if (link.internalLink) {
    // @ts-expect-error TODO: Fix this type issue later (https://linear.app/cleverfranke/issue/CFW-601/typescript-type-problem-with-sanity-data)
    if (link.internalLink.type === 'page') {
      // @ts-expect-error TODO: Fix this type issue later (https://linear.app/cleverfranke/issue/CFW-601/typescript-type-problem-with-sanity-data)
      return `${link.internalLink.slug}`;
    } else {
      // @ts-expect-error TODO: Fix this type issue later (https://linear.app/cleverfranke/issue/CFW-601/typescript-type-problem-with-sanity-data)
      return `${link.internalLink.type}/${link.internalLink.slug}`;
    }
  } else {
    return link.link ?? '';
  }
};
