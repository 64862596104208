import { Box } from '@/components/box';
import { EmployeeWithAsset } from '@/components/careers/types';
import { Text } from '@/components/text';
import { styled } from '@/stitches.config';
import { useEffect, useState } from 'react';
import { SliderComposeable } from '../../core/slider-composeable';
import shuffle from 'lodash.shuffle';
import { TreemapImage } from '@/components/treemap-image';

const AspectRatio = styled('div', {
  position: 'relative',
  width: '100%',
  aspectRatio: '1 / 1',
  '@bp2': {
    aspectRatio: '3 / 4',
  },
});

const config = {
  slides: {
    perView: 1.5,
    spacing: 30,
  },
  breakpoints: {
    '(min-width: 540px)': {
      slides: {
        perView: 2.25,
        spacing: 30,
      },
    },
    '(min-width: 768px)': {
      slides: {
        perView: 3.5,
        spacing: 30,
      },
    },
  },
};

interface TeamSliderProps {
  employees: EmployeeWithAsset[];
}

export function TeamSlider(props: TeamSliderProps) {
  const { employees } = props;

  // Start with an empty array (sorry SSR and a11y folks)
  const [shuffledEmployees, setShuffledEmployees] =
    useState<EmployeeWithAsset[]>();

  // And then sort and fill it client-side ONCE
  useEffect(() => {
    setShuffledEmployees(shuffle(employees));
    // I SAID ONCE USEFFECT!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!shuffledEmployees) {
    return null;
  }

  return (
    <SliderComposeable
      config={config}
      slides={shuffledEmployees.map((employee) => {
        const { asset } = employee.image;
        const { metadata } = asset;

        return (
          <div key={employee._id}>
            <Box
              css={{
                display: 'flex',
                flexDirection: 'column',
                gap: '$4',
                fontSize: '$3',
              }}
            >
              <AspectRatio>
                <TreemapImage
                  alt={`Portrait photo of ${employee.name}`}
                  src={asset.url}
                  layout="fill"
                  objectFit="cover"
                  palette={metadata.palette}
                  objectPosition="50% 25%"
                />
              </AspectRatio>
              <Text
                as="span"
                css={{
                  fontWeight: '500',
                }}
              >
                {employee.name}
              </Text>
              <div>{employee.title}</div>
              <Text
                as="p"
                size={{
                  '@initial': 1,
                  '@bp3': 2,
                }}
              >
                {employee.description}
              </Text>
            </Box>
          </div>
        );
      })}
      css={{
        paddingLeft: '$4',
        '@bp3': {
          paddingLeft: 'calc($6 + calc(100vw / 16))',
        },
        '@bp4': {
          paddingLeft: 'calc($8 + calc(100vw / 16))',
          paddingRight: 'calc($6 - $2)',
        },
      }}
    />
  );
}
