import type { ScrollableSection } from '@/types/sanity';

type ScrollableSectionProps = {
  children: React.ReactNode;
  value?: ScrollableSection;
};

export function ScrollableSection(props: ScrollableSectionProps) {
  if (!props.value) return null;

  const { children } = props;
  return <div id={props.value.anchor}>{children}</div>;
}
