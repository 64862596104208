import { Box } from '@/components/box';
import { ToolkitItem } from '@/lib/sanity/queries/get-toolkit-item';
import * as Icons from '@cf/icons';
import type React from 'react';
import type { SVGProps } from 'react';
import { isPresent } from 'ts-is-present';

type ToolkitIconProps = {
  item: ToolkitItem;
};

type TIcons = {
  [key: string]: React.ComponentType<SVGProps<SVGSVGElement>>;
};

export function ToolkitIcon({ item }: ToolkitIconProps) {
  let Icon: React.ComponentType | undefined;

  if (isPresent(item?.filename)) {
    const filename = item.filename;
    if (filename in Icons) {
      Icon = (Icons as unknown as TIcons)[filename];
    }
  }

  return (
    <Box
      css={{
        background: '$foreground',
        borderRadius: '100%',
        display: 'grid',
        placeContent: 'center',
        aspectRatio: '1',

        svg: {
          objectFit: 'contain',
          width: '100%',
          height: 'auto',
        },

        transition: 'transform 0.2s ease-out',
      }}
    >
      {isPresent(Icon) ? <Icon /> : <span>No icon</span>}
    </Box>
  );
}
