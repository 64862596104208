import { Box } from '@/components/box';
import { Text } from '@/components/text';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';

import { ToolkitIcon } from '../../../toolkit-items/icon';

import { styled } from '@/stitches.config';

import { SliderComposeable } from '@/components/serializers/sliders/core/slider-composeable';
import type { KeenSliderOptions } from 'keen-slider/react';
import { ProjectPhaseWithToolkitItems } from '@/components/project/types';
import type { ToolkitItem } from '@/lib/sanity/queries/get-toolkit-item';

type ToolkitItemProps = {
  className?: string;
  item: ToolkitItem;
};

type ToolkitItemsProps = {
  toolkitItemsGroupedByPhase?: ProjectPhaseWithToolkitItems[];
};

function ToolkitItemLink({
  children,
  slug,
}: {
  children: React.ReactNode;
  slug: string;
}) {
  return (
    <Link
      href={{
        pathname: slug,
      }}
      scroll={false}
      shallow={false}
      passHref
    >
      <Box
        as="a"
        css={{
          display: 'block',
          borderBottom: 0,
          paddingBottom: 0,
          mt: '$2',
          textDecoration: 'none',
          outline: 'none',
          color: '$foreground',
          transition: 'color 100ms ease-out',

          '@bp3': {
            paddingBottom: '0',
          },

          '&:hover, &:focus-visible': {
            color: '$gray11',

            [`& ${ToolkitItemWrapper}`]: {
              scale: 1,
            },
          },
        }}
      >
        {children}
      </Box>
    </Link>
  );
}

const ToolkitItemWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$5',
  position: 'relative',

  textAlign: 'center',
  scale: '0.95',

  transition: 'scale 100ms ease-out',

  svg: {
    transition: 'background 50ms ease-out',
    color: '$background',
  },
});

function ToolkitItem({ item, className }: ToolkitItemProps) {
  const router = useRouter();

  let toolkitItemSlug = `/toolkit/${item.slug.current}`;
  if (router.pathname === '/project/[...slug]') {
    toolkitItemSlug = `/project/${router.query.slug?.[0]}${toolkitItemSlug}`;
  } else if (router.query.slug?.includes('services')) {
    toolkitItemSlug = `/services${toolkitItemSlug}`;
  }

  return (
    <Box
      className={className}
      css={{
        minWidth: '6rem',
        maxWidth: '6rem',
        '@bp3': {
          minWidth: '8rem',
          maxWidth: '8rem',
        },
      }}
    >
      <ToolkitItemLink slug={toolkitItemSlug}>
        <ToolkitItemWrapper>
          <ToolkitIcon item={item} />
          <Text size={1}>{item.name}</Text>
        </ToolkitItemWrapper>
      </ToolkitItemLink>
    </Box>
  );
}

const config = {
  slides: {
    perView: 'auto',
    spacing: 30,
  },
} as KeenSliderOptions;

export function ToolkitCarousel({
  toolkitItemsGroupedByPhase,
}: ToolkitItemsProps) {
  const toolkitItems = useMemo(
    () =>
      toolkitItemsGroupedByPhase
        ?.map((el) => el.toolkit.map((item) => ({ item, phase: el.title })))
        .flat(),
    [toolkitItemsGroupedByPhase]
  );

  if (!toolkitItems) {
    return null;
  }

  return (
    <>
      <SliderComposeable
        css={{
          paddingLeft: '$4',
          '@bp3': {
            paddingLeft: 'calc($6 + calc(100vw / 16))',
          },
          '@bp4': {
            paddingLeft: 'calc($8 + calc(100vw / 16))',
            paddingRight: 'calc($9)',
          },
        }}
        config={config}
        slides={toolkitItems.map((slide) => (
          <ToolkitItem item={slide.item} key={slide.item._id} />
        ))}
      />
    </>
  );
}
