import { Box } from '@/components/box';
import { Text } from '@/components/text';
import { TLogo } from '@/lib/sanity/portable-text';
import { urlForImage } from '@/lib/sanity/sanity';
import { Logos as TLogos } from '@/types/sanity';
import { useTheme } from 'next-themes';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import Link from 'next/link';

import { styled } from '@/stitches.config';
import { getHrefFromInternalOrExternal } from '@/utils/getHrefFromInternalOrExternal';

const sharedStyles = {
  display: 'grid',
  placeContent: 'center',
  placeItems: 'center',
  $$size: '4rem',
  minHeight: '$$size',
  maxHeight: '$$size',
  position: 'relative',
};

const StyledLink = styled('a', {
  ...sharedStyles,
  '&:hover': {
    opacity: 0.5,
  },
});

const ListItem = styled('div', {
  ...sharedStyles,
});

type LogoGridProps = Omit<TLogos, '_type' | 'logos'> & {
  logos: TLogo[];
  isDimmed?: boolean;
};

type AssetProps = {
  item: TLogo;
};

function Asset({ item }: AssetProps) {
  return (
    <>
      {item.logo?.image?.asset ? (
        <Image
          layout="fill"
          alt={`Logo for ${item.logo.name}`}
          src={urlForImage(item.logo.image.asset).url() || ''}
        />
      ) : (
        <Text size={2} css={{ margin: 0 }}>
          {item.logo?.name}
        </Text>
      )}
    </>
  );
}

export function LogoGrid({ logos, isDimmed }: LogoGridProps) {
  const { resolvedTheme } = useTheme();
  const [mounted, setMounted] = useState(false);

  // When mounted on client, now we can show the UI
  useEffect(() => setMounted(true), []);

  if (!logos) {
    return null;
  }

  if (!mounted) return null;

  return (
    <Box
      css={{
        display: 'grid',
        gap: '$8',
        gridAutoRows: '1fr',
        gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
        opacity: isDimmed ? '50%' : '100%',
        my: '$9',
        ...(resolvedTheme === 'dark' ? { filter: 'invert(1)' } : {}),
        '@bp2': {
          gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
        },
        '@bp3': {
          gap: '$9',
          my: '$10',
        },
        '@bp6': {
          gridTemplateColumns: 'repeat(5, minmax(0, 1fr))',
        },
        '@bp7': {
          columnGap: '$8',
          rowGap: '$10',
          gridTemplateColumns: 'repeat(6, minmax(0, 1fr))',
        },
      }}
    >
      {logos.map((item) => {
        if (item.linkType) {
          const href = getHrefFromInternalOrExternal(item);

          if (href) {
            if (item.linkType === 'internal' && href) {
              return (
                <Link href={href} passHref key={item._key}>
                  <StyledLink
                    css={{
                      transform: `scale(${item.scale !== 1 ? item.scale : 1})`,
                      '@bp3': {
                        transform:
                          item.scale !== 1 ? `scale(${item.scale})` : undefined,
                      },
                    }}
                  >
                    <Asset item={item} />
                  </StyledLink>
                </Link>
              );
            } else if (item.linkType === 'external' && href) {
              return (
                <StyledLink
                  target="_blank"
                  href={href}
                  key={item._key}
                  css={{
                    transform: `scale(${item.scale !== 1 ? item.scale : 1})`,
                    '@bp3': {
                      transform:
                        item.scale !== 1 ? `scale(${item.scale})` : undefined,
                    },
                  }}
                >
                  <Asset item={item} />
                </StyledLink>
              );
            }
          }
        }

        return (
          <ListItem
            key={item._key}
            css={{
              transform: `scale(${item.scale !== 1 ? item.scale : 1})`,
              '@bp3': {
                transform:
                  item.scale !== 1 ? `scale(${item.scale})` : undefined,
              },
            }}
          >
            <Asset item={item} />
          </ListItem>
        );
      })}
    </Box>
  );
}
