import { Text } from '@/components/text';

import { Fragment } from 'react';
import { ProjectPhaseWithToolkitItems } from '../project/types';

import { Box } from '@/components/box';
import { ToolkitCarousel } from '@/components/serializers/sliders/instances/toolkit-carousel/toolkit-carousel';

type ProjectPhaseToolkitItemsProps = {
  projectPhases: ProjectPhaseWithToolkitItems[];
};

type PhaseTitleProps = {
  title: string;
};

function PhaseTitle({ title }: PhaseTitleProps) {
  return (
    <Text as="h3" size={5} css={{ mb: '$4' }}>
      {title}
    </Text>
  );
}

export function ProjectPhaseToolkitItems({
  projectPhases,
}: ProjectPhaseToolkitItemsProps) {
  return (
    <>
      {projectPhases.map((projectPhase) => (
        <Fragment key={`${projectPhase.title}`}>
          <Box
            css={{
              gridColumn: 'main',
              mt: '$10',
              mb: '$1',
              '&:first-child': {
                mt: 0,
              },
            }}
          >
            <PhaseTitle
              title={`${projectPhase.order}. ${projectPhase.title}`}
            />
          </Box>

          <ToolkitCarousel toolkitItemsGroupedByPhase={[projectPhase]} />
        </Fragment>
      ))}
    </>
  );
}
