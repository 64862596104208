import { styled } from '@/stitches.config';
import { SanityResolvedImage } from '@/types';
import { KeenSliderOptions } from 'keen-slider/react';
import React from 'react';
import { SanityKeyed } from 'sanity-codegen';
import { GalleryImage } from '../../../gallery/gallery-image';
import { aspectRatioStyles } from '../../../gallery/shared';
import { SliderComposeable } from '../../core/slider-composeable';

type CarouselProps<T> = {
  images: SanityKeyed<SanityResolvedImage<T>>[];
  title?: string;
  expandable: boolean;
  hasSliderHeader?: boolean;
  display: 'large-carousel' | 'small-carousel';
};

export function Carousel<T>(props: CarouselProps<T>) {
  const { images, expandable, display } = props;

  const config =
    display === 'small-carousel'
      ? {
          slides: {
            perView: 1.1,
            spacing: 30,
          },
          breakpoints: {
            '(min-width: 40em)': {
              slides: {
                perView: 1.5,
                spacing: 30,
              },
            },
            '(min-width: 52.5em)': {
              slides: {
                perView: 2.5,
                spacing: 30,
              },
            },
            '(min-width: 64em)': {
              slides: {
                perView: 3.5,
                spacing: 30,
              },
            },
          },
        }
      : ({
          slides: {
            perView: 1.1,
            spacing: 30,
          },
          breakpoints: {
            '(min-width: 40em)': {
              slides: {
                perView: 1.38,
                spacing: 30,
              },
            },
          },
        } as KeenSliderOptions);

  return (
    <SliderComposeable
      css={{
        '@bp3': {
          paddingLeft: 'calc($6 + calc(100vw / 16))',
          paddingRight: '$6',
        },
        '@bp4': {
          paddingLeft: 'calc($8 + calc(100vw / 16))',
          paddingRight: 'calc($9 - $2)',
        },
      }}
      config={config}
      slides={images.map((image) => (
        <ImageSlide key={`image-slider-${image._key}`}>
          <GalleryImage
            image={image}
            isStacked={false}
            expandable={expandable}
          />
        </ImageSlide>
      ))}
    />
  );
}

const ImageSlide = styled('div', {
  ...aspectRatioStyles,
});
