import React from 'react';
import { KeenSliderOptions } from 'keen-slider/react';

import { styled } from '@/stitches.config';

import {
  Slider as TSlider,
  Vimeo as TVimeo,
  CarouselVimeo as TCarouselVimeo,
} from '@/types/sanity';

import { Box } from '@/components/box';
import { PortableText } from '@portabletext/react';
import {
  blockWithoutContainers,
  typesWithoutContainers,
} from '@/lib/sanity/portable-text';
import { urlForImage } from '@/lib/sanity/sanity';

import Image from 'next/image';
import { Vimeo } from '@/components/serializers/vimeo';

import type {
  SanityImageCrop,
  SanityImageHotspot,
  SanityReference,
  SanityImageAsset,
} from 'sanity-codegen';
import { SliderComposeable } from '../../core/slider-composeable';

// Slides styling
const Slide = styled('div', {
  paddingRight: '$6',
  color: '$foreground',
});
const Media = styled('div', {
  width: '100%',
  aspectRatio: '1 / 1', //default but can be overridden
  position: 'relative',
  mb: '$6',
});
const Content = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

// @TODO THIS SHOULD BE EASIER TO GET FROM TSlide, but I can't figure it out.
// WE NEED TO DRY THIS UP!
type MediaImageProps = {
  image: {
    _type: 'image';
    asset: SanityReference<SanityImageAsset>;
    crop?: SanityImageCrop;
    hotspot?: SanityImageHotspot;
  };
};

const MediaImage = React.forwardRef<HTMLDivElement, MediaImageProps>(
  (props, ref) => {
    const { image } = props;

    return (
      <Media
        ref={ref}
        css={{
          // @ts-expect-error This is a false error.
          // @TODO gotta fix this up
          aspectRatio: image.asset.metadata.dimensions.aspectRatio,
        }}
      >
        <Image
          src={urlForImage(image).url()}
          alt=""
          layout="fill"
          objectFit="cover"
        />
      </Media>
    );
  }
);
MediaImage.displayName = 'MediaImage';

type MediaVimeoProps = {
  video: TVimeo | TCarouselVimeo;
};

const MediaVimeo = React.forwardRef<HTMLDivElement, MediaVimeoProps>(
  (props, ref) => {
    const { video } = props;

    const {
      posterFrame: videoPosterFrame,
      shouldUseClippingMask,
      videoId,
      overrideAspectRatio,
    } = video;

    const posterFrame = videoPosterFrame
      ? urlForImage(videoPosterFrame?.asset).url() ?? undefined
      : undefined;

    return (
      <Media
        ref={ref}
        css={{
          aspectRatio: overrideAspectRatio ? 'unset' : '16 / 9',
        }}
      >
        <Vimeo
          videoId={videoId}
          display={'full'}
          posterFrame={posterFrame}
          shouldAutoplay={true}
          shouldLoop={true}
          shouldHideControls={true}
          shouldUseClippingMask={shouldUseClippingMask}
          overrideAspectRatio={overrideAspectRatio}
        />
      </Media>
    );
  }
);
MediaVimeo.displayName = 'MediaVimeo';

const config = {
  mode: 'free-snap',
  slides: {
    spacing: 24,
    perView: 1.4,
  },
  breakpoints: {
    '(min-width: 40em)': {
      slides: {
        perView: 2.5,
      },
    },
    '(min-width: 52.5em)': {
      slides: {
        perView: 3.5,
      },
    },
    '(min-width: 64em)': {
      slides: {
        perView: 4.5,
      },
    },
  },
} as KeenSliderOptions;

type SliderProps = {
  slides: TSlider['slides'];
};

export function Slider(props: SliderProps) {
  const { slides } = props;

  if (!Array.isArray(slides)) {
    return null;
  }

  return (
    <Box
      css={{
        position: 'relative',
        gridColumn: 'full / full',
        overflow: 'hidden',
        paddingLeft: '$4',
        '@bp3': {
          paddingLeft: '$6',
        },
        '@bp4': {
          paddingLeft: '$8',
        },
      }}
    >
      <SliderComposeable
        config={config}
        slides={slides.map((slide) => {
          return (
            <Slide className="keen-slider__slide" key={`slider-${slide._key}`}>
              {slide.mediaType === 'image' && slide.image && (
                <MediaImage image={slide.image} />
              )}
              {slide.mediaType === 'video' && slide.video && (
                <MediaVimeo video={slide.video} />
              )}

              <Content>
                {slide.content && (
                  <PortableText
                    components={{
                      block: blockWithoutContainers,
                      types: typesWithoutContainers,
                    }}
                    value={slide.content}
                  />
                )}
              </Content>
            </Slide>
          );
        })}
      />
    </Box>
  );
}
