import React, { useState } from 'react';
import { Box } from './box';
import { styled } from '@/stitches.config';

import { motion, AnimatePresence } from 'framer-motion';
import { wrap } from 'popmotion';
import { useInterval } from 'react-use';
import { urlForImage } from '@/lib/sanity/sanity';
import { GalleryProps } from './serializers/gallery';

const CircleWrapper = styled(motion.div, {
  position: 'absolute',
  inset: 0,
});

const variants = {
  enter: {
    opacity: 0,
  },
  center: {
    opacity: 1,
  },
  exit: {
    opacity: 1,
  },
};

const cubicBezier = [0.7, 0, 0.4, 0.95];

const clippingAnimations = {
  enter: {
    clipPath: 'circle(0%)',
    transition: { duration: 1 },
  },
  center: {
    clipPath: 'circle(50%)',
    transition: { duration: 1.2, ease: cubicBezier },
  },
  exit: {
    clipPath: 'circle(50%)',
    transition: { duration: 2, ease: cubicBezier },
  },
};

const scaleAnimations = {
  enter: {
    scale: 1,
    transition: { duration: 1, ease: 'linear' },
  },
  center: {
    scale: 1.2,
    transition: { duration: 5, ease: 'linear' },
  },
  exit: {
    scale: 1.3,
    transition: { duration: 1, ease: 'linear' },
  },
};

const scaleFrameAnimations = {
  enter: {
    clipPath: 'circle(0%)',
    transition: { duration: 1 },
  },
  center: {
    clipPath: 'circle(33%)',
    transition: { duration: 0.8, delay: 0.25, ease: cubicBezier },
  },
  exit: {
    clipPath: 'circle(50%)',
    transition: { duration: 0.7 },
  },
};

export function BlurImageAnimation(props: Pick<GalleryProps, 'images'>) {
  const { images } = props;

  const [[page], setPage] = useState([0, 0]);

  useInterval(() => {
    setPage([page + 1, 1]);
  }, 5000);

  if (!images) {
    console.warn(
      'Warning: BlurImageAnimation components expects images as prop but did not receive any.'
    );
    return null;
  }

  // We only have 3 images, but we paginate them absolutely (ie 1, 2, 3, 4, 5...) and
  // then wrap that within 0-2 to find our image ID in the array below. By passing an
  // absolute page index as the `motion` component's `key` prop, `AnimatePresence` will
  // detect it as an entirely new image. So you can infinitely paginate as few as 1 images.
  const imageIndex = wrap(0, images.length, page);

  const imageUrl = urlForImage(images[imageIndex]).url();

  // In the unlikely case that imageUrl is undefined, don't render anything
  if (!imageUrl) {
    return null;
  }

  return (
    // TODO: Remove BOX styling once Sanity is set up
    <>
      <Box
        css={{
          width: '100%',
          height: 'auto',
          position: 'relative',
          aspectRatio: '1 / 1',
        }}
      >
        <AnimatePresence mode="sync">
          <CircleWrapper
            key={`wrapper-${page}`}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
          >
            <motion.div
              variants={clippingAnimations}
              transition={{
                opacity: { duration: 1 },
                scale: { duration: 2 },
              }}
            >
              <Frame>
                <ScaledImage src={imageUrl} alt="" variants={scaleAnimations} />
              </Frame>
              <FrameSmall variants={scaleFrameAnimations}>
                <Image src={imageUrl} alt="" variants={scaleAnimations} />
              </FrameSmall>
            </motion.div>
          </CircleWrapper>
        </AnimatePresence>
      </Box>
    </>
  );
}

const Image = styled(motion.img, {
  position: 'absolute',
  top: 0,
  left: 0,
  display: 'block',
  borderRadius: '50%',
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  objectPosition: 'center',
});

const ScaledImage = styled(Image, {
  filter: 'blur(0.5em)',
});

const Frame = styled('div', {
  position: 'relative',
  aspectRatio: 1 / 1,
  width: '100%',
  height: 'auto',
  borderRadius: '50%',
  overflow: 'hidden',
  inset: 0,
});

const FrameSmall = styled(motion.div, {
  aspectRatio: 1 / 1,
  height: '100%',
  width: 'auto',
  borderRadius: '50%',
  overflow: 'hidden',
  position: 'absolute',
  top: 0,
  left: 0,
});
