import { ExpandingImage } from '@/components/expanding-image';
import { TreemapImage } from '@/components/treemap-image';
import { urlForImage } from '@/lib/sanity/sanity';
import { SanityResolvedImage } from '@/types';
import NextImage from 'next/image';

interface GalleryImageProps {
  image: SanityResolvedImage<{ alt?: string }>;
  shouldAnimateSlower?: boolean;
  isStacked: boolean;
  expandable: boolean;
}

export function GalleryImage(props: GalleryImageProps) {
  const { image, isStacked, expandable } = props;

  const { asset, disableTreemapAnimation } = image;

  const { metadata } = asset;

  /**
   *
   * When a file is uploaded to Sanity, it can contain a `metadata` array.
   * When this is the case, Sanity will not generate a `metadata.palette` for the image.
   *
   * - More info: https://www.sanity.io/docs/image-metadata#5bb0c7e96f42
   *
   * The boolean `treemapImpossible` ensures to at least return a `<NextImage />` when Sanity wasn't able
   * to generate a palette (and returning a `<Treemap/>` would result in an error).
   *
   * - Ticket on linear to properly fix this issue:
   * https://linear.app/cleverfranke/issue/CFW-570/fix-handle-treemap-images-properly-when-there-is-no-sanity-generated
   */
  const treemapImpossible = disableTreemapAnimation || !metadata.palette;

  if (!metadata) {
    console.warn(
      'We are missing image metadata, but trying to render an image. Check your GROQ query for: ',
      props
    );
    return null;
  }

  const { dimensions, lqip } = metadata;

  const imageUrl = urlForImage(image?.asset).url();
  if (!imageUrl) {
    return null;
  }

  if (isStacked) {
    return (
      <>
        {treemapImpossible ? (
          <NextImage
            alt={image?.alt}
            draggable="false"
            src={imageUrl}
            width={dimensions.width}
            height={dimensions.height}
            layout="responsive"
            placeholder="blur"
            blurDataURL={lqip}
          />
        ) : (
          <TreemapImage
            alt={image?.alt}
            draggable="false"
            src={imageUrl}
            layout="responsive"
            width={dimensions.width}
            height={dimensions.height}
            palette={image.asset.metadata.palette}
          />
        )}
      </>
    );
  }

  if (expandable) {
    return <ExpandingImage image={image} />;
  }

  if (disableTreemapAnimation) {
    return (
      <NextImage
        alt={image?.alt}
        draggable="false"
        src={imageUrl}
        layout="fill"
        objectFit="cover"
        placeholder="blur"
        blurDataURL={lqip}
      />
    );
  }

  return (
    <TreemapImage
      alt={image?.alt}
      draggable="false"
      src={imageUrl}
      layout="fill"
      objectFit="cover"
      palette={image.asset.metadata.palette}
    />
  );
}
