/**
 * Inspired by https://tailwindcss-typography.netlify.app/ the Prose component
 * adds a bunch of default styles for out of the box, good typography
 */

import { styled } from '@/stitches.config';

export const Prose = styled('div', {
  ol: {
    listStyleType: 'decimal',
  },

  'ol ol': {
    listStyleType: 'lower-alpha',
  },

  'ol ol ol': {
    listStyleType: 'lower-roman',
  },

  'ul ul': {
    listStyleType: 'circle',
  },

  'ul ul ul': {
    listStyleType: 'square',
  },
});
