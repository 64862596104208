import { Box } from '@/components/box';
import { OpenPositionsTable } from '@/components/careers/open-positions-table';
import { EmployeeWithAsset } from '@/components/careers/types';
import { EnterAnimation } from '@/components/enter-animaton';
import { GridContainer } from '@/components/grid-container';
import { ProjectPhaseWithToolkitItems } from '@/components/project/types';
import { TeamSlider } from '@/components/serializers/sliders/instances/team-carousel';
import { VideoSlider } from '@/components/serializers/sliders/instances/video-carousel';

import { Milestones } from '@/components/milestones';
import { AwardsTable } from '@/components/serializers/awards-table';
import { StandaloneBlockquote as Blockquote } from '@/components/serializers/blockquote';
import { TextAndImages } from '@/components/serializers/text-and-images';
import { Columns } from '@/components/serializers/columns';
import { ExternalLink } from '@/components/serializers/external-link';
import { Gallery } from '@/components/serializers/gallery';
import { LogoGrid } from '@/components/serializers/logo-grid';
import { ProjectPhaseToolkitItems } from '@/components/serializers/project-phase-toolkit-items';
import { Slider } from '@/components/serializers/sliders/instances/generic-slider/slider';
import { Publicity } from '@/components/serializers/sliders/instances/publicity-carousel/publicity';
import { Statistics } from '@/components/serializers/statistics';
import { TextAndQuote } from '@/components/serializers/text-and-quote';
import { ThreeColumnsText } from '@/components/serializers/three-columns-text';
import { ToolkitItems } from '@/components/serializers/toolkit-items';
import { UnorderedList } from '@/components/serializers/unordered-list';
import { Vimeo } from '@/components/serializers/vimeo';
import { ScrollableSection } from '@/components/serializers/scrollable-section';

import { StaggeredText } from '@/components/staggered-text';
import { Text } from '@/components/text';
import { CSS, styled } from '@/stitches.config';
import { SanityResolvedImage } from '@/types';
import {
  AwardsTable as TAwardsTable,
  Client,
  Columns as TColumns,
  ExternalLink as TExternalLink,
  Gallery as TGallery,
  TextAndImages as TTextAndImages,
  Logos as TLogos,
  Milestones as TMilestones,
  Publicity as TPublicity,
  PublicityItem as PublicityItemBase,
  Quote,
  Slider as TSlider,
  Statistics as TStatistics,
  TeamCarousel as ITeamCarousel,
  TextAndQuote as TTextAndQuote,
  ThreeColumnsText as IThreeColumnsText,
  ToolkitPhases as TToolkitPhases,
  VideoCarousel as IVideoCarousel,
  Vimeo as IVimeo,
} from '@/types/sanity';
import { PortableTextComponentProps } from '@portabletext/react';
import React, { useMemo } from 'react';
import { SanityImageAsset, SanityKeyed } from 'sanity-codegen';
import { isPresent } from 'ts-is-present';
import { urlForImage } from './sanity';
import {
  focusStateStyles,
  Underline,
  underlineHoverStyles,
} from '@/components/underline';
import { ProjectStatistic } from '../schemas/project-statistic';

// We want all building blocks for serializers to live in src/components/serializers
// These building blocks should be designed for composeability!

type QuoteProps = PortableTextComponentProps<Quote>;
type StatisticsProps = PortableTextComponentProps<
  Omit<TStatistics, 'stats'> & {
    stats: ProjectStatistic[];
  }
>;
type TextAndQuoteProps = PortableTextComponentProps<TTextAndQuote>;
type VimeoProps = PortableTextComponentProps<IVimeo>;

export interface TextAndResolvedImages
  extends Omit<TTextAndImages, 'imageRows'> {
  imageRows?: Array<
    SanityKeyed<{
      _type: 'imageRow';
      overrideAspectRatio?: boolean;
      width?: number;
      height?: number;
      images?: Array<
        SanityKeyed<SanityResolvedImage<{ alt?: string }>> | SanityKeyed<IVimeo>
      >;
    }>
  >;
}

type TextAndImagesProps = PortableTextComponentProps<TextAndResolvedImages>;

export interface PublicityItem
  extends Omit<SanityKeyed<PublicityItemBase>, 'image'> {
  image?: Omit<PublicityItemBase['image'], 'asset'> & {
    asset: SanityImageAsset;
  };
}

type PublicityProps = PortableTextComponentProps<
  Omit<TPublicity, 'publicity'> & {
    publicity?: PublicityItem[];
  }
>;

type ToolkitItemsProps = PortableTextComponentProps<
  SanityKeyed<{
    _type: 'toolkitItems';
    toolkitItems?: ProjectPhaseWithToolkitItems[];
  }>
>;

type ProjectPhaseToolkitItemsProps = PortableTextComponentProps<
  Omit<TToolkitPhases, 'placeholder'> & {
    toolkitPhases?: ProjectPhaseWithToolkitItems[];
  }
>;

export type ExternalLinkProps = PortableTextComponentProps<
  Omit<TExternalLink, 'internalLink'> & {
    internalLink?: {
      type?: string;
      slug?: string;
    };
  }
>;

type GalleryProps = PortableTextComponentProps<
  Omit<TGallery, 'images'> & {
    images?: SanityKeyed<SanityResolvedImage<{ alt?: string }>>[];
  }
>;

export type TLogo = Omit<TLogos['logos'][number], 'logo'> & {
  logo: Pick<Client, 'name'> & {
    image?: SanityResolvedImage;
  };
  internalLink:
    | {
        type: string;
        slug: string;
      }
    | undefined;
};

type LogosGridProps = PortableTextComponentProps<
  Omit<TLogos, 'logos'> & { logos: TLogo[] }
>;

type LinkProps = {
  children: React.ReactNode;
  value?: {
    href?: string;
  };
};

type HeadingProps = {
  children?: string | React.ReactNode;
};

export type AwardsTableProps = {
  rows: TAwardsTable['rows'];
  minimumVisibleRows: Pick<TAwardsTable, 'minimumVisibleRows'>;
};

function MaybeStaggerText({ children }: { children: React.ReactNode }) {
  if (
    typeof children === 'string' ||
    (Array.isArray(children) &&
      children.every((c): c is string => typeof c === 'string'))
  ) {
    return <StaggeredText text={children} />;
  }

  return <>{children}</>;
}

function H1({ children, css }: { children?: React.ReactNode; css?: CSS }) {
  return (
    <Text
      size={{
        '@initial': 11,
        '@bp2': 12,
        '@bp4': 15,
      }}
      css={css}
      as="h1"
    >
      <MaybeStaggerText>{children}</MaybeStaggerText>
    </Text>
  );
}
function H2({ children }: HeadingProps) {
  return (
    <Text
      size={{
        '@initial': 11,
        '@bp2': 12,
        '@bp4': 15,
      }}
      as="h2"
      css={{ marginTop: '$8', marginLeft: 'calc($1 * -1)' }}
    >
      <MaybeStaggerText>{children}</MaybeStaggerText>
    </Text>
  );
}
function H3({ children }: HeadingProps) {
  return (
    <Text
      size={{
        '@initial': 6,
        '@bp4': 12,
      }}
      as="h3"
      css={{ marginTop: '$8' }}
    >
      <MaybeStaggerText>{children}</MaybeStaggerText>
    </Text>
  );
}
function H4({ children }: HeadingProps) {
  return (
    <Text size={5} as="h4">
      <MaybeStaggerText>{children}</MaybeStaggerText>
    </Text>
  );
}
function H5({ children }: HeadingProps) {
  return (
    <Text size={4} as="h5">
      <MaybeStaggerText>{children}</MaybeStaggerText>
    </Text>
  );
}
function H6({ children }: HeadingProps) {
  return (
    <Text size={3} as="h6" css={{ mb: '$3', fontWeight: '$heavy' }}>
      <MaybeStaggerText>{children}</MaybeStaggerText>
    </Text>
  );
}
function NormalText({ children }: { children?: React.ReactNode }) {
  return (
    <Text
      as="p"
      size={{
        '@initial': 2,
        '@bp3': 3,
      }}
      css={{
        maxWidth: '65ch',

        // Adds default bottom whitepace where there's only 1 NormalText component is present
        // It allows for consistent white space between NormalText and unorder list elements
        '&:first-of-type': {
          '&:has(+ ul)': {
            marginBottom: '$6',
          },
        },

        '&:has(+ ul)': {
          marginBottom: 0,
        },
        // On 3 column stacking elements we remove the default margin value from the last child
        // It allows for consistent white space between each element even if we have more than 2 sets of 3 column grids
        '&:last-child': {
          marginBottom: 0,

          // Revert to original value for larger screens
          '@bp3': {
            marginBottom: '$6',
          },
        },
      }}
    >
      {children}
    </Text>
  );
}
function Strong(props: { children?: React.ReactNode }) {
  return (
    <Box as="span" css={{ fontWeight: 500 }}>
      {props.children}
    </Box>
  );
}

const StyledA = styled('a', {
  color: '$foreground',
  display: 'inline-flex',
  margin: '0 !important',
  position: 'relative',
  textDecoration: 'underline',
  textDecorationThickness: 2,

  overflow: 'hidden',

  '&:hover': {
    textDecoration: 'none',
  },
  variants: {
    underline: {
      true: {
        textDecoration: 'underline',
        textDecorationThickness: 2,
        '&:hover': {
          textDecoration: 'none',
        },
      },
      false: {
        textDecoration: 'none',
        '&:hover, &:active': {
          [`${Underline} > div `]: {
            ...underlineHoverStyles,
            animationDuration: '300ms',
          },
        },
        '&:focus-visible': {
          ...focusStateStyles,
          outlineOffset: '2px',
          [`${Underline} > div `]: {
            ...underlineHoverStyles,
            animationDuration: '300ms',
          },
        },
      },
    },
  },
});
function Anchor(props: LinkProps) {
  const { children, value } = props;

  if (!value) return null;
  if (!value.href) {
    console.warn('Found an anchor with a missing href:', children, value);
  }

  const rel = !value?.href?.startsWith('/') ? 'noreferrer noopener' : undefined;

  return (
    <StyledA
      href={value.href}
      rel={rel}
      underline={
        !(value?.href?.includes('mailto:') || value?.href?.includes('tel:'))
      }
    >
      {children}
      <Underline />
    </StyledA>
  );
}

const defaultBottomWhiteSpace = {
  mb: '$6',
  '@bp4': {
    mb: '$12',
  },
};

const blockWithContainers = {
  // Customize block types with ease
  h1: ({ children }: { children?: React.ReactNode }) => (
    <GridContainer>
      <H1
        css={{
          marginLeft: 'calc($2 * -1)',
          gridColumn: 'main-start / main-end',
          '@bp3': {
            gridColumn: 'main-start / span 12',
          },
        }}
      >
        {children}
      </H1>
    </GridContainer>
  ),
  h2: ({ children }: { children?: React.ReactNode }) => (
    <GridContainer>
      <H2>{children}</H2>
    </GridContainer>
  ),
  h3: ({ children }: { children?: React.ReactNode }) => (
    <GridContainer>
      <H3>{children}</H3>
    </GridContainer>
  ),
  h4: ({ children }: { children?: React.ReactNode }) => (
    <GridContainer>
      <H4>{children}</H4>
    </GridContainer>
  ),
  h5: ({ children }: { children?: React.ReactNode }) => (
    <GridContainer>
      <H5>{children}</H5>
    </GridContainer>
  ),
  h6: ({ children }: { children?: React.ReactNode }) => (
    <GridContainer>
      <H6>{children}</H6>
    </GridContainer>
  ),
  normal: ({ children }: { children?: React.ReactNode }) => {
    return <NormalText>{children}</NormalText>;
  },
};

export const blockWithoutContainers = {
  // Customize block types with ease
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
  normal: NormalText,
};

export const typesWithoutContainers = {
  externalLink: ExternalLink,
  gallery: function GalleryWithoutContainerRenderer(props: GalleryProps) {
    if (!isPresent(props.value)) return null;

    const { images, display, expandable } = props.value;

    if (!isPresent(images) || images?.length === 0) {
      return null;
    }

    return (
      <Gallery images={images} display={display} expandable={expandable} />
    );
  },
};

export const components = {
  block: blockWithContainers,
  marks: {
    strong: Strong,
    link: Anchor,
    scrollableSection: ScrollableSection,
  },

  list: {
    bullet: UnorderedList,
  },

  types: {
    slider: function SliderRenderer(props: { value: TSlider }) {
      return (
        <EnterAnimation css={defaultBottomWhiteSpace}>
          <GridContainer>
            <Slider slides={props.value.slides} />
          </GridContainer>
        </EnterAnimation>
      );
    },

    columns: function ColumnsRenderer(props: { value: SanityKeyed<TColumns> }) {
      return (
        <EnterAnimation css={defaultBottomWhiteSpace}>
          <GridContainer>
            <Columns data={props?.value} />
          </GridContainer>
        </EnterAnimation>
      );
    },
    gallery: function GalleryRenderer(props: GalleryProps) {
      if (!isPresent(props.value)) return null;

      const { images, display, expandable } = props.value;

      if (!isPresent(images) || images?.length === 0) {
        return null;
      }

      return (
        <GridContainer css={defaultBottomWhiteSpace}>
          <Gallery images={images} display={display} expandable={expandable} />
        </GridContainer>
      );
    },
    toolkitPhases: function ProjectPhaseToolkitItemsRenderer(
      props: ProjectPhaseToolkitItemsProps
    ) {
      if (!isPresent(props.value.toolkitPhases)) return null;

      return (
        <EnterAnimation css={defaultBottomWhiteSpace}>
          <GridContainer>
            <ProjectPhaseToolkitItems
              projectPhases={props.value.toolkitPhases}
            />
          </GridContainer>
        </EnterAnimation>
      );
    },
    toolkitItems: function ToolkitItemsRenderer(props: ToolkitItemsProps) {
      if (!isPresent(props.value.toolkitItems)) return null;
      return (
        <EnterAnimation css={{ mb: '$9' }}>
          <GridContainer>
            <ToolkitItems
              toolkitItemsGroupedByPhase={props.value.toolkitItems}
            />
          </GridContainer>
        </EnterAnimation>
      );
    },
    statistics: function StatisticsRenderer(props: StatisticsProps) {
      return (
        <EnterAnimation css={defaultBottomWhiteSpace}>
          <GridContainer>
            <Statistics
              alignment={props.value.alignment}
              statistics={props.value.stats}
            />
          </GridContainer>
        </EnterAnimation>
      );
    },
    vimeo: function VimeoRenderer(props: VimeoProps) {
      const posterFrameUrl = useMemo(() => {
        if (props.value.posterFrame) {
          const url = urlForImage(props.value.posterFrame).width(1024).url();
          if (url) return url;
        }
      }, [props.value.posterFrame]);

      return (
        <GridContainer css={defaultBottomWhiteSpace}>
          <Vimeo
            videoId={props.value.videoId}
            shouldAutoplay={props.value.shouldAutoplay}
            shouldLoop={props.value.shouldLoop}
            shouldHideControls={props.value.shouldHideControls}
            overrideAspectRatio={props.value.overrideAspectRatio}
            display={props.value.display}
            posterFrame={posterFrameUrl}
            title={props.value.title}
          />
        </GridContainer>
      );
    },
    quote: function QuoteRenderer(props: QuoteProps) {
      return (
        <EnterAnimation css={defaultBottomWhiteSpace}>
          <GridContainer>
            <Blockquote
              source={props.value.source}
              quotee={props.value.quotee}
              alignment={props.value.alignment}
            >
              {props.value.quote}
            </Blockquote>
          </GridContainer>
        </EnterAnimation>
      );
    },
    externalLink: function ExternalLinkRenderer(props: ExternalLinkProps) {
      return (
        <EnterAnimation>
          <GridContainer css={{ justifyItems: 'center' }}>
            <ExternalLink {...props} />
          </GridContainer>
        </EnterAnimation>
      );
    },
    publicity: function PublicityRenderer(props: PublicityProps) {
      if (!isPresent(props.value.publicity)) return null;

      return (
        <EnterAnimation css={defaultBottomWhiteSpace}>
          <GridContainer>
            <Publicity
              title={props.value.title}
              publicityItems={props.value.publicity}
            />
          </GridContainer>
        </EnterAnimation>
      );
    },
    textAndImages: function TextAndImagesRenderer(props: TextAndImagesProps) {
      return (
        <EnterAnimation
          css={{
            mb: '$6',
            '@bp4': {
              mb: '$12',
            },
          }}
        >
          <GridContainer>
            <TextAndImages
              stickyText={!!props.value.stickyText}
              stickyMedia={!!props.value.stickyMedia}
              isRightAligned={props.value.isRightAligned}
              text={props.value.text}
              imageRows={props.value.imageRows}
            />
          </GridContainer>
        </EnterAnimation>
      );
    },
    textAndQuote: function TextAndQuoteRenderer(props: TextAndQuoteProps) {
      return (
        <EnterAnimation css={defaultBottomWhiteSpace}>
          <GridContainer>
            <TextAndQuote
              quote={props.value.quote}
              text={props.value.text}
              quoteAlignment={props.value.quoteAlignment ? 'right' : 'left'}
            />
          </GridContainer>
        </EnterAnimation>
      );
    },
    logos: (props: LogosGridProps) => {
      return (
        <EnterAnimation css={defaultBottomWhiteSpace}>
          <GridContainer>
            <LogoGrid
              logos={props.value.logos}
              isDimmed={props.value.isDimmed}
            />
          </GridContainer>
        </EnterAnimation>
      );
    },
    threeColumnsText: function ThreeColumnsTextRenderer(props: {
      value: IThreeColumnsText;
    }) {
      return (
        <EnterAnimation css={defaultBottomWhiteSpace}>
          <GridContainer>
            <ThreeColumnsText {...props.value} />
          </GridContainer>
        </EnterAnimation>
      );
    },
    bigText: function BigText(props: {
      value: {
        text: React.ReactNode[];
      };
    }) {
      return (
        <EnterAnimation
          css={{
            mb: '$6',
            '@bp4': {
              mb: '$10',
            },
          }}
        >
          <GridContainer>
            <Box
              css={{
                gridColumn: 'main-start / main-end',
                '@bp4': {
                  gridColumn: 'main-start / span 10',
                },
                '@bp5': {
                  gridColumn: 'main-start / span 8',
                },
              }}
            >
              <Text
                as="p"
                size={{
                  '@initial': 5,
                  '@bp4': 6,
                }}
              >
                {props.value.text}
              </Text>
            </Box>
          </GridContainer>
        </EnterAnimation>
      );
    },
    awardsTable: function AwardsTableRenderer(props: {
      value: AwardsTableProps;
    }) {
      return (
        <EnterAnimation css={defaultBottomWhiteSpace}>
          <GridContainer>
            <Box
              css={{
                '@bp3': {
                  gridColumn: 'main-start / span 14',
                },
              }}
            >
              <AwardsTable
                rows={props.value.rows}
                minimumVisibleRows={props.value.minimumVisibleRows}
              />
            </Box>
          </GridContainer>
        </EnterAnimation>
      );
    },
    videoCarousel: function VideoCarouselRenderer(props: {
      value: IVideoCarousel;
    }) {
      return (
        <GridContainer css={defaultBottomWhiteSpace}>
          <VideoSlider slides={props.value.slides} />
        </GridContainer>
      );
    },
    openPositions: function OpenPositionsRenderer() {
      return (
        <GridContainer css={defaultBottomWhiteSpace}>
          <OpenPositionsTable />
        </GridContainer>
      );
    },
    teamCarousel: function TeamCarouselRenderer(props: {
      value: ITeamCarousel & {
        employees: EmployeeWithAsset[];
      };
    }) {
      return (
        <GridContainer css={defaultBottomWhiteSpace}>
          <TeamSlider employees={props.value.employees} />
        </GridContainer>
      );
    },
    milestones: function Render(props: { value: TMilestones }) {
      if (!Array.isArray(props.value?.slides)) return null;

      return (
        <GridContainer css={defaultBottomWhiteSpace}>
          <Milestones milestones={props.value.slides} />
        </GridContainer>
      );
    },
  },
};
