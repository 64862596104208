import { Box } from '@/components/box';
import { VimeoPlayer } from '@/components/vimeo-player';
import { styled } from '@/stitches.config';
import { Vimeo as SanityVimeo } from '@/types/sanity';
import { AnimatePresence, motion } from 'framer-motion';
import NextImage from 'next/image';
import { useState } from 'react';

interface VimeoProps extends Omit<SanityVimeo, 'posterFrame' | '_type'> {
  posterFrame?: string | undefined;
  overrideAspectRatio?: boolean | undefined;
  width?: number;
  height?: number;
  light?: boolean;
}

const VimeoTitle = styled('div', {
  fontWeight: '500',
  marginBottom: '$4',
  variants: {
    shouldIndent: {
      true: {
        marginLeft: '$4',
        '@bp3': {
          marginLeft: '$6',
        },
        '@bp4': {
          marginLeft: '$8',
        },
      },
    },
  },
});

export function Vimeo({
  videoId,
  shouldAutoplay,
  display = 'normal',
  posterFrame,
  overrideAspectRatio = false,
  shouldUseClippingMask = false,
  width,
  height,
  shouldHideControls,
  shouldLoop,
  title,
  light = false,
}: VimeoProps) {
  const [showCover, setShowCover] = useState(true);

  // Override the aspect ratio if the toggle is active and both width and height are defined
  let aspectRatioWidth = 16;
  let aspectRatioHeight = 9;

  if (shouldUseClippingMask) {
    aspectRatioWidth = 1;
    aspectRatioHeight = 1;
  } else if (overrideAspectRatio && width && height) {
    aspectRatioWidth = width;
    aspectRatioHeight = height;
  }

  return (
    <Container display={display}>
      {title ? (
        <VimeoTitle shouldIndent={display === 'full'}>{title}</VimeoTitle>
      ) : null}

      <Inner
        css={{
          ...(shouldUseClippingMask
            ? { clipPath: 'circle(49.5% at center)' }
            : {}),
          paddingTop: `${(aspectRatioHeight / aspectRatioWidth) * 100}%`,
        }}
      >
        {showCover && (
          <AnimatePresence>
            <motion.div
              key="overlay"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Box
                id="cover"
                css={{
                  position: 'absolute',
                  inset: 0,
                  background: 'black',
                  zIndex: 5,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  overflow: 'hidden',
                }}
              >
                {posterFrame && (
                  <NextImage
                    alt={'alt'}
                    draggable="false"
                    src={posterFrame}
                    layout="fill"
                    placeholder="blur"
                    blurDataURL={posterFrame}
                    objectFit="cover"
                  />
                )}
              </Box>
            </motion.div>
          </AnimatePresence>
        )}
      </Inner>

      {videoId && (
        <VimeoPlayer
          videoId={videoId}
          onStartedChange={(started) => setShowCover(!started)}
          autoplay={shouldAutoplay ? 'in-view' : 'none'}
          hideControls={shouldHideControls}
          loop={shouldLoop}
          light={light}
          aspectRatio={
            overrideAspectRatio && (!width || !height)
              ? 'native'
              : aspectRatioWidth / aspectRatioHeight
          }
          css={
            shouldUseClippingMask
              ? { clipPath: 'circle(49.3% at center)' }
              : undefined
          }
        />
      )}
    </Container>
  );
}

const Container = styled('div', {
  variants: {
    display: {
      normal: {
        gridColumn: 'wide !important',
      },
      full: {
        gridColumn: 'full !important',
      },
    },
  },
  defaultVariants: {
    display: 'normal',
  },
});

const Inner = styled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
});
