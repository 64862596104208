import { JobsContext } from '@/components/careers/context';
import { ExternalLink } from '@/components/icons/external-link';
import { VisuallyHidden } from '@/components/visually-hidden';
import {
  BackgroundBlockLeft,
  BackgroundBlockRight,
} from '@/components/button-link';
import { styled } from '@/stitches.config';
import Link from 'next/link';
import React, { useContext } from 'react';
import { JobProps } from '../types';

export function OpenPositionsTable() {
  const jobs = useContext(JobsContext);

  const getDuplicateContent = (job: JobProps) => {
    return (
      <DuplicateContentBlock>
        <span>{job?.title}</span>
        <ExternalLink width={12} height={12} />
      </DuplicateContentBlock>
    );
  };

  return (
    <Table>
      <Thead>
        <tr>
          <Th>City</Th>
          <Th>Position</Th>
        </tr>
      </Thead>
      <TBody>
        {Array.isArray(jobs) &&
          jobs.map((job, index) => {
            return (
              <tr key={job.id}>
                <Td isFirstChild={index === 0}>
                  {index > 0 ? (
                    <VisuallyHidden>
                      {job.location_type === 'on-site' ? 'Utrecht' : undefined}
                    </VisuallyHidden>
                  ) : (
                    <>
                      {job.location_type === 'on-site' ? 'Utrecht' : undefined}
                    </>
                  )}
                </Td>
                <Td isFirstChild={index === 0}>
                  <Link href={job.job_url} passHref>
                    <Position target="_blank">
                      <Content>
                        <span>{job.title}</span>
                        <ExternalLink width={12} height={12} />
                      </Content>
                      <BackgroundBlockLeft
                        aria-hidden="true"
                        css={{
                          zIndex: '9',
                          padding: 0,
                          paddingLeft: PADDING_SIZE,
                          transition: 'clip-path 350ms ease-out',
                        }}
                      >
                        {getDuplicateContent(job)}
                      </BackgroundBlockLeft>
                      <BackgroundBlockRight
                        aria-hidden="true"
                        css={{
                          zIndex: '1',

                          padding: 0,
                          paddingLeft: PADDING_SIZE,
                          transition: 'clip-path 350ms ease-out',
                          transitionDelay: '160ms',
                        }}
                      >
                        {getDuplicateContent(job)}
                      </BackgroundBlockRight>
                    </Position>
                  </Link>
                </Td>
              </tr>
            );
          })}
      </TBody>
    </Table>
  );
}
const PADDING_SIZE = '$3';

const Table = styled('table', {
  borderCollapse: 'collapse',
  fontSize: '$3',
});

const Thead = styled('thead', {
  fontWeight: 400,
});

const Th = styled('th', {
  fontWeight: 500,
  textAlign: 'left',
  borderBottom: '2px solid currentColor',
  paddingBottom: '$3',
  paddingLeft: PADDING_SIZE,
  '&:nth-child(odd)': {
    paddingRight: '$4',
    paddingLeft: '0',
  },
});

const TBody = styled('tbody', {
  position: 'relative',
});

const DEFAULT_LEFT_BLOCK_HOVER_STATE = {
  [`${BackgroundBlockLeft}`]: {
    clipPath:
      'polygon(-1px -1px, 50% -1px, 50% calc(100% + 1px), -1px calc(100% + 1px))', // rectangle with full height
  },
};

const DEFAULT_RIGHT_BLOCK_HOVER_STATE = {
  [`${BackgroundBlockRight}`]: {
    clipPath:
      'polygon(calc(50% - 1px) -1px, calc(100% + 1px) -1px, calc(100% + 1px) calc(100% + 1px), calc(50% - 1px) calc(100% + 1px))', // rectangle with full height
  },
};

const Td = styled('td', {
  position: 'relative',
  fontWeight: '$heavy',
  padding: '$3 0',

  overflow: 'hidden',

  '&:nth-child(odd)': {
    paddingRight: '$4',
  },

  '&:nth-child(even)': {
    paddingLeft: PADDING_SIZE,
  },

  '@bp2': {
    '&:nth-child(odd)': {
      width: '45%',
    },

    '&:nth-child(even)': {
      width: '55%',
    },
  },
  variants: {
    isFirstChild: {
      true: {
        paddingTop: '$3',
      },
    },
  },

  '&:not(:first-child)': {
    // // Position column, underline for each row
    '&:after': {
      content: "''",
      position: 'absolute',
      bottom: 0,
      right: 0,
      width: `calc(100% - ${PADDING_SIZE})`,

      height: 1,
      backgroundColor: '$gray8',
      transition: 'transform 100ms ease-out',
    },
  },
});

const Position = styled('a', {
  display: 'block',
  textDecoration: 'none',
  color: '$foreground',

  '&:hover, &:active': {
    ...DEFAULT_LEFT_BLOCK_HOVER_STATE,
    ...DEFAULT_RIGHT_BLOCK_HOVER_STATE,
  },

  '&:focus-visible': {
    outlineStyle: 'dashed',
    outlineWidth: '1px',
    outlineColor: '$foreground',
    outlineOffset: '2px',
  },
});

const Content = styled('span', {
  display: 'inline-flex',
  my: '$2',
  textDecoration: 'none',
  position: 'relative',
  gap: '$3',
  alignItems: 'baseline',
});

const DuplicateContentBlock = styled('a', {
  display: 'inline-flex',
  my: '$2',
  textDecoration: 'none',
  color: '$background',
  position: 'relative',
  gap: '$3',
  alignItems: 'baseline',
});
