import {
  blockWithoutContainers,
  typesWithoutContainers,
} from '@/lib/sanity/portable-text';
import { ThreeColumnsText as ThreeColumnsType } from '@/types/sanity';
import { PortableText } from '@portabletext/react';
import { Box } from '../box';
import { Prose } from '../prose';

export function ThreeColumnsText({
  firstColumn,
  secondColumn,
  thirdColumn,
}: ThreeColumnsType) {
  return (
    <Box
      css={{
        display: 'grid',
        // We want to match up  with the row gap in the Columns component and the bottom margin of the EnterAnimation component
        // Ensures the white space between 3 stacking elements on mobile is consistent
        gridRowGap: '$6',

        '@bp3': {
          display: 'contents',
        },
      }}
    >
      <Prose
        css={{
          '@bp3': {
            gridColumn: 'main-start / span 7',
          },
          '@bp4': {
            gridColumn: 'main-start / span 5',
          },
        }}
      >
        {firstColumn && (
          <PortableText
            value={firstColumn}
            components={{
              block: blockWithoutContainers,
              types: typesWithoutContainers,
            }}
          />
        )}
      </Prose>
      <Prose
        css={{
          '@bp3': {
            gridColumn: '10 / span 7',
          },
          '@bp4': {
            gridColumn: '8 / span 5',
          },
        }}
      >
        {secondColumn && (
          <PortableText
            value={secondColumn}
            components={{
              block: blockWithoutContainers,
              types: typesWithoutContainers,
            }}
          />
        )}
      </Prose>
      <Prose
        css={{
          '@bp3': {
            gridColumn: 'main-start / span 7',
          },
          '@bp4': {
            gridColumn: '13 / span 5',
          },
        }}
      >
        {thirdColumn && (
          <PortableText
            value={thirdColumn}
            components={{
              block: blockWithoutContainers,
              types: typesWithoutContainers,
            }}
          />
        )}
      </Prose>
    </Box>
  );
}
