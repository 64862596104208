import { Box } from '@/components/box';
import { hierarchy, treemap, treemapSquarify } from 'd3-hierarchy';
import Image, { ImageProps } from 'next/image';
import { useEffect, useRef, useState } from 'react';
import { useIntersection } from 'react-use';
import { keyframes, styled } from '@/stitches.config';
import { useIsomorphicLayoutEffect } from 'framer-motion';
import { SanityImagePalette } from '@/lib/schemas/sanity-image-palette';
import { SanityImagePaletteSwatch } from '@/lib/schemas/sanity-image-palette-swatch';

interface TreemapProps extends ImageProps {
  palette: SanityImagePalette;
  canAnimate?: boolean;
  animationState?: 'initial' | 'leave' | 'enter';
  onReady?: () => void;
}

type Node = {
  name: string;
  color: SanityImagePaletteSwatch['background'];
  total: SanityImagePaletteSwatch['population'];
};

export function TreemapImage({
  src,
  alt,
  palette,
  canAnimate = true,
  animationState = 'initial',
  onReady,
  ...rest
}: TreemapProps) {
  const intersectionRef = useRef(null);
  const [hasPassedIntersectionPoint, setHasPassedIntersectionPoint] =
    useState(false);
  const [isImageLoaded, setImageIsLoaded] = useState(false);

  const onReadyRef = useRef(onReady);
  onReadyRef.current = onReady;

  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0.3,
  });

  useEffect(() => {
    if (intersection?.isIntersecting && !hasPassedIntersectionPoint) {
      setHasPassedIntersectionPoint(true);
      intersectionRef.current = null;
    }
  }, [intersection?.isIntersecting, hasPassedIntersectionPoint]);

  const paletteData: { children: Node[] } = {
    children: [
      {
        name: 'darkMuted',
        color: palette.darkMuted.background,
        total: palette.darkMuted.population,
      },
      {
        name: 'darkVibrant',
        color: palette.darkVibrant.background,
        total: palette.darkVibrant.population,
      },
      {
        name: 'lightMuted',
        color: palette.lightMuted.background,
        total: palette.lightMuted.population,
      },
      {
        name: 'lightVibrant',
        color: palette.lightVibrant.background,
        total: palette.lightVibrant.population,
      },
      {
        name: 'muted',
        color: palette.muted.background,
        total: palette.muted.population,
      },
      {
        name: 'vibrant',
        color: palette.vibrant.background,
        total: palette.vibrant.population,
      },
    ],
  };

  const root = hierarchy(paletteData).sum((d) => (d as unknown as Node).total);
  const leaves = root.leaves();

  /**
   * This parses and transforms the input. It will update root data to
   * contain x0, x1, y0, y1 values. Subsequently, leafs will also have those values.
   * The magic of JS references?
   */
  treemap().tile(treemapSquarify).size([100, 100]).padding(0).round(true)(root);

  const isReady = hasPassedIntersectionPoint && isImageLoaded && canAnimate;

  useEffect(() => {
    isReady && onReadyRef.current && onReadyRef.current();
  }, [isReady]);

  const imageContainerRef = useRef<HTMLDivElement>(null);

  // reset animation when state changes
  // as per https://stackoverflow.com/questions/6268508/restart-animation-in-css3-any-better-way-than-removing-the-element
  useIsomorphicLayoutEffect(() => {
    if (!imageContainerRef.current) {
      return;
    }
    imageContainerRef.current.style.animation = 'none';
    imageContainerRef.current.offsetHeight; /* trigger reflow */
    imageContainerRef.current.style.animation = '';
  }, [animationState]);

  return (
    <Box
      ref={intersectionRef}
      css={{
        position: 'absolute',
        inset: 0,
        overflow: 'hidden',
      }}
    >
      <ImageContainer
        ref={imageContainerRef}
        css={{
          $$baseDuration: duration * 1000,
        }}
        state={isReady ? animationState : undefined}
      >
        <Image
          alt={alt}
          src={src}
          onLoadingComplete={() => {
            setImageIsLoaded(true);
          }}
          {...rest}
        />
        <ImageOverlay
          css={{
            $$baseDuration: duration * 1000,
          }}
          state={isReady ? animationState : undefined}
        />
      </ImageContainer>

      {/* @TODO avoid use of any, ticket > CFW-617 */}
      {leaves.map((leaf: any, index: number) => {
        return (
          <Box
            key={`dominant-color-${index}-${leaf.data.name}`}
            css={{
              position: 'absolute',
              top: `${leaf.y0}%`,
              left: `${leaf.x0}%`,
              width: `${leaf.x1 - leaf.x0}%`,
              height: `${leaf.y1 - leaf.y0}%`,
              overflow: 'hidden',
            }}
          >
            <TreemapInnerLeaf
              css={{
                background: leaf.data.color,
                $$baseDuration: duration * 1000,
              }}
              state={isReady ? animationState : undefined}
            />
          </Box>
        );
      })}
    </Box>
  );
}

const duration = 2.25;
const ease = [0.73, 0, 0.13, 1];

const imagePanIn = keyframes({
  '0%': { transform: 'translateY(8%)' },
  '100%': { transform: 'translateY(0)' },
});

const imagePanOut = keyframes({
  '0%': { transform: 'translateY(0)' },
  '100%': { transform: 'translateY(-4%)' },
});

const imageDisappear = keyframes({
  '0%': { opacity: 1 },
  '100%': { opacity: 0 },
});

const imageAppear = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

const ImageOverlay = styled('div', {
  $$baseDuration: 0,
  position: 'absolute',
  inset: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.2)',
  opacity: 1,
  variants: {
    state: {
      initial: {
        $$duration: 'calc($$baseDuration * 0.5ms)',
        $$delay: 'calc($$baseDuration * 0.1ms)',
        opacity: 0,
        transition: '$$duration $$delay opacity ease-out',
      },
      leave: {
        opacity: 1,
        $$duration: 'calc($$baseDuration * 0.25ms)',
        transition: '$$duration 0s opacity ease-in',
      },
      enter: {
        opacity: 0,
        $$duration: 'calc($$baseDuration * 0.25ms)',
        $$delay: 'calc($$baseDuration * 0.75ms)',
        transition: '$$duration $$delay opacity ease-out',
      },
    },
  },
});

const ImageContainer = styled('div', {
  $$baseDuration: 0,
  inset: 0,
  position: 'absolute',
  variants: {
    state: {
      initial: {
        $$duration: 'calc($$baseDuration * 0.5ms)',
        animation: `${imagePanIn} $$duration cubic-bezier(${ease.join(',')})`,
        animationFillMode: 'both',
      },
      leave: {
        $$panDuration: 'calc($$baseDuration * 0.25ms)',
        $$disappearDelay: 'calc($$baseDuration * 0.5ms)',
        animation: `${imagePanOut} $$panDuration cubic-bezier(${ease.join(
          ','
        )}), ${imageDisappear} 0ms $$disappearDelay`,
        animationFillMode: 'both',
      },
      enter: {
        $$panDuration: 'calc($$baseDuration * 0.5ms)',
        $$panDelay: 'calc($$baseDuration * 0.5ms)',
        $$appearDelay: 'calc($$baseDuration * 0.5ms)',
        animation: `${imagePanIn} $$panDuration $$panDelay cubic-bezier(${ease.join(
          ','
        )}), ${imageAppear} 0ms $$appearDelay`,
        animationFillMode: 'both',
      },
    },
  },
});

const leafInitial = keyframes({
  '0%': { transform: 'translateY(0)' },
  '100%': { transform: 'translateY(-100%)' },
});

const leafEnter = keyframes({
  '0%': { transform: 'translateY(0) scaleY(0)' },
  '40%': { transform: 'translateY(0) scaleY(100%)' },
  '60%': { transform: 'translateY(0) scaleY(100%)' },
  '100%': { transform: 'translateY(-100%) scaleY(100%)' },
});

const TreemapInnerLeaf = styled('div', {
  $$baseDuration: '0ms',
  position: 'absolute',
  inset: -1,
  transformOrigin: 'bottom',
  variants: {
    state: {
      initial: {
        $$duration: 'calc($$baseDuration * 0.5ms)',
        animation: `${leafInitial} $$duration cubic-bezier(0.37, 0, 0.28, 1)`,
        animationFillMode: 'forwards',
      },
      leave: {
        transform: 'scaleY(0)',
      },
      enter: {
        $$duration: 'calc($$baseDuration * 1ms)',
        animation: `${leafEnter} $$duration cubic-bezier(0.37, 0, 0.28, 1)`,
        animationFillMode: 'forwards',
      },
    },
  },
});
