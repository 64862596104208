import { styled } from '@/stitches.config';

export const CarouselArrowButton = styled('button', {
  unset: 'all',
  outline: 0,
  margin: 0,
  border: 0,
  padding: 0,

  display: 'flex',
  position: 'relative',
  zIndex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '$gray2',
  borderRadius: 99999,
  width: '$8',
  height: '$8',
  color: '$gray',

  boxShadow: '$colors$blackA11 0px 2px 12px -5px, $colors$blackA5 0px 1px 3px',
  willChange: 'transform, box-shadow, opacity',
  transition: 'all 100ms',

  '@hover': {
    '&:hover': {
      boxShadow:
        '$colors$blackA10 0px 3px 16px -5px, $colors$blackA5 0px 1px 3px',

      // Fix a bug when hovering at button edges would cause the button to jitter because of transform
      '&::before': {
        content: '',
        inset: -2,
        borderRadius: 99999,
        position: 'absolute',
      },
    },
  },
  '&:focus': {
    boxShadow: `
        $colors$blackA10 0px 3px 16px -5px,
        $colors$blackA5 0px 1px 3px,
        $colors$blue8 0 0 0 2px
      `,
    transform: 'translateY(-1px)',
  },
  '&:focus:not(:focus-visible)': {
    boxShadow:
      '$colors$blackA11 0px 2px 12px -5px, $colors$blackA5 0px 1px 3px',
  },
  '&:active:not(:focus)': {
    boxShadow:
      '$colors$blackA11 0px 2px 12px -5px, $colors$blackA5 0px 1px 3px',
  },
  '&:active': {
    transform: 'none',
    transition: 'opacity 100ms',
  },
  '&:disabled': {
    opacity: 0,
  },
});
