import { Box } from '@/components/box';
import { Text } from '@/components/text';
import { ProjectPhaseWithToolkitItems } from '../../project/types';
import { ToolkitCarousel } from '../sliders/instances/toolkit-carousel/toolkit-carousel';

type ToolkitItemsProps = {
  toolkitItemsGroupedByPhase?: ProjectPhaseWithToolkitItems[];
};

export function ToolkitItems({
  toolkitItemsGroupedByPhase,
}: ToolkitItemsProps) {
  if (!toolkitItemsGroupedByPhase) {
    return null;
  }

  return (
    <>
      <Box
        css={{
          gridColumn: 'main !important',
        }}
      >
        <Text
          size={5}
          as="h3"
          css={{
            marginTop: '$6',
            marginBottom: '$3',
          }}
        >
          Phases and toolkit items used
        </Text>
      </Box>
      <Box
        css={{
          position: 'relative',
          overflow: 'hidden',
          gridColumn: 'full !important',
          a: {
            borderBottom: 0,
            paddingBottom: 0,
            textDecoration: 'none',
          },
        }}
      >
        <ToolkitCarousel
          toolkitItemsGroupedByPhase={toolkitItemsGroupedByPhase}
        />
      </Box>
    </>
  );
}
