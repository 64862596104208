import { VideoCarousel } from '@/types/sanity';

import { SliderComposeable } from '../../core/slider-composeable';

import { Box } from '@/components/box';
import { Text } from '@/components/text';
import { Vimeo } from '../../../vimeo';

import { IS_SAFARI_IPHONE } from '@/components/vimeo-player';
import { urlForImage } from '@/lib/sanity/sanity';

const config = {
  slides: {
    perView: 1.5,
    spacing: 30,
  },
  breakpoints: {
    '(min-width: 768px)': {
      slides: {
        perView: 2.5,
        spacing: 30,
      },
    },
  },
};

type VideoSliderProps = {
  slides: VideoCarousel['slides'];
};

export function VideoSlider({ slides }: VideoSliderProps) {
  if (!Array.isArray(slides)) {
    return null;
  }

  return (
    <>
      <SliderComposeable
        css={{
          paddingLeft: '$4',
          paddingRight: '$4',
          '@bp3': {
            paddingLeft: 'calc($6 + calc(100vw / 16))',
            paddingRight: '$6',
          },
          '@bp4': {
            paddingLeft: 'calc($8 + calc(100vw / 16))',
            paddingRight: 'calc($9 - $2)',
          },
        }}
        config={config}
        slides={slides.map((slide) => {
          const {
            display,
            posterFrame: videoPosterFrame,
            shouldAutoplay,
            shouldLoop,
            shouldHideControls,
            overrideAspectRatio,
            videoId,
          } = slide.vimeo;

          const posterFrame = videoPosterFrame
            ? urlForImage(videoPosterFrame?.asset).width(1024).url() ??
              undefined
            : undefined;

          return (
            <Box
              css={{
                display: 'flex',
                flexDirection: 'column',
                gap: '$5',
              }}
              key={`video-slider-${videoId}`}
            >
              <Vimeo
                videoId={videoId}
                display={display}
                posterFrame={posterFrame}
                light={IS_SAFARI_IPHONE ? true : false}
                shouldAutoplay={shouldAutoplay}
                shouldLoop={shouldLoop}
                shouldHideControls={shouldHideControls}
                overrideAspectRatio={overrideAspectRatio}
              />
              <Text size="3">{slide.description}</Text>
            </Box>
          );
        })}
      />
    </>
  );
}
